import * as React from 'react';
import { Grid, Typography, IconButton, Box, TextField, Button, Select, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowBack } from '@mui/icons-material/';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css';
import { connect } from 'react-redux';
import { getCookie } from '../Utils';
import Navbar from '../Components/Navbar';

function NewExpert({ user }) {
  const navigate = useNavigate()
  const [newexpert, setnewExpert] = React.useState({ studies: [] });
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [waitingMessage, setWaitingMessage] = React.useState("")
// Validation des valeurs saisies par l'utilisateur
  const validation = () => {
    if (newexpert.firstName && newexpert.password && newexpert.username) {
      return true;
    }
    else {
      setErrorMessage('Veuillez renseigner toutes les informations nécessaires!')
      return false;
    }
  }

  const passwordValidation = () => {

    if (newexpert.password.length >= 6) {
      return true;
    }
    else {
      setErrorMessage('Le mot de passe doit contenir plus de 6 caractères')
      return false;
    }
  }


  const delay = ms => new Promise(res => setTimeout(res, ms));

  //L'ajout du patient dans la base de données
  const submitHandle = async () => {
    console.log(newexpert);
    const { access, is_staff } = user || {};
    if (validation() && passwordValidation()) {
      setWaitingMessage("L'expert est en train d'être enregistrer ...")
      axios.post(process.env.REACT_APP_API + '/api/new-expert/', newexpert, {
        headers: {
          Authorization: `Bearer ${access}`,
          'X-CSRFToken': getCookie('csrftoken')
        }
      },).then(
        (response) => {
          setSuccessMessage("Expert ajouté avec succes!")
          setErrorMessage('');
          setWaitingMessage('')
        }
      ).catch((error) => {
        console.log(error);
        setErrorMessage('');
        setWaitingMessage('')
        setErrorMessage("Une erreur est survenue");

      })
      await delay(3000);
      setSuccessMessage("")
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setnewExpert({ ...newexpert, [name]: value });
  }

  return (

    <div style={{ backgroundColor: "#0e2852", height: "100vh" }}>
      <Navbar isConnected />
      <Grid container xs={12} style={{ marginTop: "5% " }}>

        <Grid item xs={1.25} />
        <Grid item xs={2} style={{ paddingRight: "20px", paddingLeft: "20px" }}>
          <Box style={{ marginLeft: "40%" }}>
            <IconButton sx={{ color: '#FFFF' }} onClick={() => navigate("/admin/experts")}  >
              <ArrowBack fontSize="large" />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box style={{
            overflowY: "hidden", backgroundColor: "white", borderRadius: "15px",
            paddingTop: "50px", paddingLeft: "10%", paddingBottom: "15px"
          }} className='scroll'>
            <Grid className="formLine" item container xs={12} >
              <Grid item xs={4}>
                <Typography className="formText">Prénom:*</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.firstName || ''}
                  name="firstName" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} >
              <Grid item xs={4}>
                <Typography className="formText">Nom:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.lastName || ''}
                  name="lastName" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} >
              <Grid item xs={4}>
                <Typography className="formText">ID:*</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.ID || ''}
                  name="ID" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} >
              <Grid item xs={4}>
                <Typography className="formText">Organization:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.organization || ''}
                  name="organization" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} >
              <Grid item xs={4}>
                <Typography className="formText">Email:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.email || ''}
                  name="email" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} >
              <Grid item xs={4}>
                <Typography className="formText">Nom d'utilisateur:*</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.username || ''}
                  name="username" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} >
              <Grid item xs={4}>
                <Typography className="formText">Mot de passe:*</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField type='password' size='small' style={{ width: '35vh' }} value={newexpert.password || ''}
                  name="password" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            {errorMessage ?
              (<Alert severity="error" className="alert">{errorMessage}</Alert>) : null}
            {successMessage ?
              (<Alert severity="success" className="alert">{successMessage}</Alert>) : null}
            {waitingMessage ?
              (<Alert severity="info" className="alert">{waitingMessage}</Alert>) : null}
            <Button style={{ float: "right", marginRight: "35px" }} variant="contained" onClick={() => { submitHandle() }}>Ajouter</Button>
          </Box>
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>
    </div>

  );
}

const mapStateToProps = state => ({
  user: state.user,
});

//  export default connect(mapStateToProps, mapDispatchToProps)(ExpertAdmin);
export default connect(mapStateToProps, null)(NewExpert);