import { Box, Button, Grid, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUser } from '../redux/user/user-action'

function Navbar({ isConnected, isExpert, setUser }) {
    const navigate = useNavigate()
    console.log('isExpert:', isExpert, 'isConnected:', isConnected)

    return isExpert ? (
        <Grid
            container
            item
            xs={12}
            style={{
                backgroundColor: '#303233',
                height: '7.5vh',
                marginBottom: '6vh',
            }}
        >
            <Grid item xs={6}>
                <Box
                    component="img"
                    sx={{
                        height: '7vh',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        display: 'grid',
                        placeItems: 'center',
                    }}
                    alt="comet web"
                    src={process.env.REACT_APP_STATIC + 'images/logo.png'}
                />
            </Grid>
            <Grid item xs={2.5} />
            <Grid item xs={2} sx={{ paddingTop: '2vh', paddingLeft: '2vh' }}>
                <Button
                    onClick={() => {
                        isConnected
                            ? navigate('/studies')
                            : navigate('/expert/login')
                    }}
                >
                    <Typography
                        sx={{
                            color: 'white',
                            fontSize: '1.7vh',
                            fontFamily: 'Kanit',
                        }}
                    >
                        {isConnected ? 'MES ÉTUDES' : 'CONNEXION'}
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={0.5} />
            <Grid item xs={1}>
                <Box
                    component="img"
                    sx={{
                        marginLeft: '3vh',
                        height: '6.5vh',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        display: 'grid',
                        placeItems: 'center',
                    }}
                    alt="comet web."
                    src={
                        process.env.REACT_APP_STATIC + 'images/syneika-rem.png'
                    }
                />
            </Grid>
        </Grid>
    ) : (
        <Grid
            container
            item
            xs={12}
            style={{
                backgroundColor: '#303233',
                height: '75px',
                marginBottom: '88px',
            }}
        >
            <Grid item xs={6}>
                <Box
                    component="img"
                    sx={{
                        height: '7vh',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        display: 'grid',
                        placeItems: 'center',
                    }}
                    alt="comet web."
                    src={process.env.REACT_APP_STATIC + 'images/logo.png'}
                />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={2} sx={{ paddingTop: '20px', paddingLeft: '80px' }}>
                {isConnected && isExpert ? (
                    <Button
                        onClick={() => {
                            setUser({})
                            navigate('/expert/login')
                        }}
                    >
                        <Typography sx={{ color: 'white' }}>
                            SE DÉCONNECTER
                        </Typography>
                    </Button>
                ) : isConnected ? (
                    <Button
                        onClick={() => {
                            setUser({})
                            navigate('/admin/login')
                        }}
                    >
                        <Typography sx={{ color: 'white' }}>
                            SE DÉCONNECTER
                        </Typography>
                    </Button>
                ) : null}
            </Grid>
            <Grid item xs={1}>
                <Box
                    component="img"
                    sx={{
                        marginLeft: '20px',
                        height: '7vh',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        display: 'grid',
                        placeItems: 'center',
                    }}
                    alt="comet web."
                    src={
                        process.env.REACT_APP_STATIC + 'images/syneika-rem.png'
                    }
                />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    setUser: (studies) => dispatch(setUser(studies)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
